<template>
  <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
    <template slot="button-content">
      <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
        <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ email }}</span>
        <!-- <img src="@/assets/jas-ui-icons-color/icon-test-account.svg" class="avatar-icon mr-1" /> -->
      </span>
    </template>
    <b-dd-item> <i class="jas-icons-settings" /> User settings </b-dd-item>
    <b-dd-divider />
    <b-dd-item @click.prevent="logout()"> <i class="jas-icons-logout-rounded-left text-danger" /> Sign Out </b-dd-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('user', ['uid', 'displayName', 'email', 'clientName', 'customerId', 'customerName'])
    // ...mapMutations('user', ['setClient']),
  },
  data: () => ({
    loading: true
  }),

  methods: {
    logout () {
      try {
        this.$authApp.signOut()
      } catch (error) {
        console.error(error)
        this.$bvModal.msgBoxOk(error.message, {
          title: 'Error',
          size: 'sm',
          centered: true
        })
      }
    }
  }
}
</script>
