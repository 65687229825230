<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x"
  >
    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand to="/" class="app-brand demo py-0 mr-4">
      <span class="app-brand-logo demo">
        <img src="@/assets/spplogo-muted.png" style="height: 30px;" />
      </span>
    </b-navbar-brand>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
        <label class="nav-item navbar-text navbar-search-box p-0 active">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input
              type="text"
              class="form-control navbar-text mx-2"
              placeholder="Search..."
              style="width:200px"
            />
          </span>
        </label>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <div
          class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
        >|</div>

       <user-dropdown />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>

import UserDropdown from '@/components/UserDropdown'

export default {
  name: 'app-layout-navbar',
  components: {
    UserDropdown
  },
  props: {
  },

  data: () => ({
    user: {
      name: 'none',
      email: 'none'
    }
  }),

  methods: {
    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    }
  }

}
</script>
