// roles
// 0: viewer -- needs to be whitelisted for worktasks & risk assessments
// 1: editor -- can edit modules and exercises
// 2: admin -- can manager users
// 3: super admin

const defaultState = () => {
  return {
    uid: '',
    email: '',
    displayName: '',
    role: 0
  }
}
const state = defaultState()

// getters
const getters = {
  isViewer: (state) => state.role >= 0,
  isContributor: (state) => state.role >= 1,
  isEditor: (state) => state.role >= 2,
  isAdmin: (state) => state.role >= 3,
  isSuperAdmin: (state) => state.role >= 4,
  canEditItem: (state) => (item) => state.isEditor || item.writeUsers.includes(this.uid)
}

// mutations
const mutations = {
  loggedIn (state, user) {
    if (user) {
      state.uid = user.uid
      state.email = user.email
      state.displayName = user.displayName
      state.role = user.role
    } else {
      this.loggedOut(state)
    }
  },
  loggedOut (state) {
    Object.assign(state, defaultState())
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
