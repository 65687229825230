/** @format */

import { firebase } from '@firebase/app'
import '@firebase/firestore'
import '@firebase/functions'
import '@firebase/auth'
import '@firebase/storage'
import '@/lib/firebaseExtensions'

export const config = {
  perPage: 20,
  isDev: window.location.origin.includes('devadmin.speedpowerplay.com') || window.location.origin.includes('localhost'),
}

const prodCredentials = {
  apiKey: 'AIzaSyD0AqLPiG7L63pwxoPyvEw-luwY83AFOQI',
  authDomain: 'speedpowerplay.firebaseapp.com',
  databaseURL: 'https://speedpowerplay.firebaseio.com',
  projectId: 'speedpowerplay',
  storageBucket: 'speedpowerplay.appspot.com',
  messagingSenderId: '350271259524',
  appId: '1:350271259524:web:9037c7da3dfd021a0e93d1',
  measurementId: 'G-0VXBMX1ST5'
}

const devCredentials = {
  apiKey: 'AIzaSyCSIpdpL4zJ2e8Uz0oS0Ce1Y7QsXMs_Ads',
  authDomain: 'speedpowerplaydev.firebaseapp.com',
  databaseURL: 'https://speedpowerplaydev.firebaseio.com',
  projectId: 'speedpowerplaydev',
  storageBucket: 'speedpowerplaydev.appspot.com',
  messagingSenderId: '50176293979',
  appId: '1:50176293979:web:934ad2995c399037f2d37e',
  measurementId: 'G-M36YVSLDMC'
}

const { Timestamp, DocumentReference, CollectionReference, FieldValue, GeoPoint } = firebase.firestore
export { Timestamp, DocumentReference, CollectionReference, FieldValue, GeoPoint }

export const firebaseApp = firebase.initializeApp(config.isDev ? devCredentials : prodCredentials)
export const firestoreApp = firebaseApp.firestore()
firestoreApp.enablePersistence().catch(function (err) {
  if (err.code === 'failed-precondition') {
    console.log('existing tab open')
  } else if (err.code === 'unimplemented') {
    alert(
      "This browser does not support offline data.  You can still continue, however the application won't be as fast"
    )
  }
})

export const authApp = firebaseApp.auth()
export const storageApp = firebaseApp.storage()
export const functionsApp = firebaseApp.functions()

export default firebaseApp
