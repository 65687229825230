<template>
  <span class="h6">
    <b-badge pill v-for="tag in tags" class="mr-1"  v-bind:key="tag" :style="tagStyle(tag)">{{
      tag
    }}</b-badge>
  </span>
</template>

<script>
import { hashColor, foregroundColorFromBackgroundColor } from '@/lib/colorUtilities'
export default {
  props: {
    tags: {
      required: true
    }
  },
  methods: {
    tagStyle (tag) {
      const backgroundColor = hashColor(tag)
      const foregroundColor = foregroundColorFromBackgroundColor(backgroundColor)
      return `color:${foregroundColor};background-color:${backgroundColor};`
    }

  }
}
</script>
