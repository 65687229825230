const state = {
  orderBy: 'name',
  perPage: 25,
  currentPage: 1,
  orderDesc: true
}
// mutations
const mutations = {
  setCurrentPage (state, currentPage) {
    state.currentPage = currentPage
  },
  setPerPage (state, perPage) {
    if (![25, 100, 500].includes(perPage)) return
    state.perPage = perPage
  },
  setOrderBy (state, field) {
    if (!['name'].includes(field)) return
    state.orderBy = field
  },
  setOrderDesc (state, desc) {
    state.orderDesc = desc
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
