<template>
  <div class="page-header">
    <h5 class="pageHeaderH5 mb-1">
      <span v-for="pathComponent in pathComponents" :key="pathComponent.title">
        <router-link :to="pathComponent.path" class="text-muted"
          ><img :src="pathComponent.image" v-if="pathComponent.image" class="path-icon pr-2" />
          <i v-if="pathComponent.icon" :class="pathComponent.icon" class="pr-2" />{{ pathComponent.title | capitalise }}</router-link
        >
        <span class="text-very-muted"> / </span>
      </span>
    </h5>
    <header class="pb-0 mb-0">
      <h4 class="d-flex justify-content-between align-items-center w-100 font-weight-semibold mb-1">
        <div>
          <span class="font-weight-bold"
            ><img :src="image" v-if="image" class="main-icon pr-2" /><i :class="icon" class="pr-1" v-if="icon" />{{
              title | capitalise
            }}
          </span>
        </div>
        <b-btn v-if="actionTitle && actionPath" :to="actionPath" variant="primary" class="d-block"
          ><span :class="actionIcon" class="pr-1" v-if="actionIcon" /> {{ actionTitle }}</b-btn
        >
      </h4>
      <tag-display :tags="tags" />
    </header>
  </div>
</template>

<style src="@/vendor/libs/vue-input-tag/vue-input-tag.scss" lang="scss"></style>

<script>
import TagDisplay from '@/components/TagDisplay'

export default {
  name: 'page-header',
  components: {
    TagDisplay
  },
  props: {
    pathComponents: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false
    },
    image: {
      type: String,
      required: false
    },
    tags: {
      type: Array,
      required: false,
      default: () => []
    },

    actionTitle: {
      type: String,
      required: false,
      default: null
    },
    actionIcon: {
      type: String,
      required: false,
      default: null
    },
    actionPath: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>
