
import LayoutBlank from '@/layout/LayoutBlank'

export default [{
  // Blank layout
  path: '/auth',
  component: LayoutBlank,
  children: [{
    path: 'login',
    name: 'authLogin',
    component: () => import('@/pages/auth/Login')
  }, {
    path: 'passwordreset',
    name: 'authPasswordReset',
    component: () => import('@/pages/auth/PasswordReset')
  }]
}]
