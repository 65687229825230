const state = {
  orderBy: 'displayName',
  orderDesc: true
}
// mutations
const mutations = {
  setOrderBy (state, field) {
    if (!['displayName'].includes(field)) return
    state.orderBy = field
  },
  setOrderDesc (state, desc) {
    state.orderDesc = desc
  }

}

export default {
  namespaced: true,
  state,
  mutations
}
