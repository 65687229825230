import Vuex, { Store } from 'vuex'
import Vue from 'vue'

import user from '@/store/user'
import exercises from '@/pages/exercises/Store'
import programs from '@/pages/programs/Store'
// import tags from '@/pages/tags/Store'
import users from '@/pages/users/Store'

import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
var ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

// eslint-disable-next-line new-cap
const persistedState = new createPersistedState({
  key: 'sppadmin',
  // paths: [workTasks, riskAssessments, sites, workAreas, people, tags, users],
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key)
  }
})

export default new Store({
  modules: {
    user,
    exercises,
    programs,
    // tags,
    users
  },
  strict: debug,
  plugins: [persistedState]
})
