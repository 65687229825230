<template>
  <div>
    <b-btn v-on:click="$emit('cancel-pressed')" variant="default" class="mr-4"
      ><span v-if="cancelIcon" :class="cancelIcon" class="jas-icons-cancel pr-1" />{{ cancelText }}</b-btn
    >
    <ladda-btn
      @click.native="$emit('save-pressed')"
      type="button"
      :loading="loading"
      data-style="expand-right"
      class="btn btn-primary"
      ><span v-if="saveIcon" :class="saveIcon" class="pr-1" />{{ saveText }}</ladda-btn
    >
  </div>
</template>

<script>
import LaddaBtn from '@/vendor/libs/ladda/Ladda'

export default {
  name: 'save-cancel-buttons',
  components: {
    LaddaBtn
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    saveText: {
      type: String,
      required: false,
      default: () => 'Save changes'
    },
    saveIcon: {
      type: String,
      required: false,
      default: () => 'jas-icons-save'
    },
    cancelText: {
      type: String,
      required: false,
      default: () => 'Cancel'
    },
    cancelIcon: {
      type: String,
      required: false,
      default: () => 'jas-icons-cancel'
    }
  }
}
</script>
