import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import { authApp } from '../firebaseApp'

import globals from '@/globals'

import store from '@/store/store'

// Layouts
import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

import authRoutes from '@/pages/auth/Routes'
import exercisesRoutes from '@/pages/exercises/Routes'
import programsRoutes from '@/pages/programs/Routes'
import usersRoutes from '@/pages/users/Routes'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  {
    path: '/',
    component: LayoutHorizontalSidenav,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/Home/Home'),
        meta: {
          requiresAuth: true,
        },
      },
    ]
      .concat(exercisesRoutes)
      .concat(programsRoutes)
      .concat(usersRoutes),
  },
].concat(authRoutes)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: ROUTES,
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  console.log(`to: ${to.name} from: ${from.name}`)

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin) || false

  // Set loading state
  document.body.classList.add('app-loading')

  const currentUser = authApp.currentUser
  if (requiresAuth && !currentUser) {
    setTimeout(() => next('auth/login'), 10)
    return
  }

  const isAdmin = store.getters['user/isAdmin']
  if (requiresAdmin && !isAdmin) {
    setTimeout(() => next('/'), 10)
    return
  }
  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
