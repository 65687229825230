import Vue from 'vue'
import App from './App'
import router from '@/router'
import store from '@/store/store'
import BootstrapVue from 'bootstrap-vue'
import globals from '@/globals'
import Popper from 'popper.js'

// firebase imports
import { config, firebaseApp, authApp, firestoreApp, storageApp, functionsApp } from '@/firebaseApp'
import { firestorePlugin } from 'vuefire'

import '@/components'
import '@/filters'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)

// config firebase
Vue.prototype.$config = config
Vue.prototype.$firebaseApp = firebaseApp
Vue.prototype.$authApp = authApp
Vue.prototype.$firestoreApp = firestoreApp
Vue.prototype.$storageApp = storageApp
Vue.prototype.$functionsApp = functionsApp
Vue.use(firestorePlugin)

// Global RTL flag
Vue.mixin({
  data: globals
})

// app init and authstate
let app
authApp.onAuthStateChanged(async function (user) {
  if (user) {
    try {
      const userDetails = await getUserDetails(user)
      store.commit('user/loggedIn', userDetails)
      await router.push({ name: 'home' })
    } catch (error) {
      console.error(error)
      this.$bvModal.msgBoxOk(error.message, {
        title: 'Error',
        size: 'sm',
        centered: true
      })
    }
  } else {
    store.commit('user/loggedOut')
    try {
      await router.push({ name: 'authLogin' })
    } catch (error) {
      console.error(error)
      this.$bvModal.msgBoxOk(error.message, {
        title: 'Error',
        size: 'sm',
        centered: true
      })
    }
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  }
})

async function getUserDetails (user) {
  const idTokenResult = await user.getIdTokenResult()
  const authedUser = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    token: idTokenResult.token,
    role: idTokenResult.claims.role
  }
  return authedUser
}
