<template>
  <label class="switcher mt-2 mb-4">
    <input type="checkbox" class="switcher-input" v-model="currentValue" />
    <span class="switcher-indicator">
      <span class="switcher-yes" />
      <span class="switcher-no" />
    </span>
  </label>
</template>
<script>
export default {
  name: 'switcher',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },

      set (val) {
        console.log(`updated: ${val}`)
        this.$emit('input', val)
      }
    }
  }
}
</script>
