import { firebase } from '@firebase/app'
import '@firebase/firestore'

firebase.firestore.Timestamp.prototype.isNever = function () {
  if (!this) {
    return true
  }
  return this.seconds <= 0 || this.seconds >= 100000000000
}

firebase.firestore.Timestamp.distantPast = function () {
  return new firebase.firestore.Timestamp(0, 0)
}

firebase.firestore.Timestamp.distantFuture = function () {
  return new firebase.firestore.Timestamp(100000000000, 0)
}

firebase.firestore.Firestore.prototype.libraryCollection = function () {
  return this.collection('spplib')
}

firebase.firestore.CollectionReference.prototype.contentDocument = function () {
  return this.doc('content')
}

firebase.firestore.DocumentReference.prototype.exercisesCollection = function () {
  return this.collection('exercises')
}
firebase.firestore.DocumentReference.prototype.programsCollection = function () {
  return this.collection('programs')
}
firebase.firestore.DocumentReference.prototype.usersCollection = function () {
  return this.collection('users')
}
