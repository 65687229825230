import Vue from 'vue'
import ValidatedTextInput from '@/components/ValidatedTextInput'
import PageHeader from '@/components/PageHeader'
import SaveCancelButtons from '@/components/SaveCancelButtons'
import Switcher from '@/components/Switcher'
import Multiselect from 'vue-multiselect'
import TagDisplay from '@/components/TagDisplay'
import LaddaBtn from '@/vendor/libs/ladda/Ladda'

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'

// validation config
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})
localize('en', en)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidatedTextInput', ValidatedTextInput)
Vue.component('PageHeader', PageHeader)
Vue.component('SaveCancelButtons', SaveCancelButtons)
Vue.component('Switcher', Switcher)
Vue.component('Multiselect', Multiselect)
Vue.component('LaddaBtn', LaddaBtn)
Vue.component('TagDisplay', TagDisplay)
