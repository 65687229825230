import Vue from 'vue'
import moment from 'moment/moment'

Vue.filter('moment', function (value, formatString = 'Do MMM YYYY', invalidString = '', neverString = '-') {
  if (!value) return invalidString
  if (value instanceof Date) {
    return value.getSeconds() <= 0 || value.getSeconds() >= 100000000000
      ? neverString
      : moment(value).format(formatString)
  }
  return value.isNever() ? neverString : moment(value.toDate()).format(formatString)
})

Vue.filter('capitalise', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('simplifyAnd', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace(' and ', ' & ')
})

Vue.filter('tooltipDelete', function (value) {
  if (!value) return 'Delete'
  value = value.toString()
  return `Delete ${value}`
})

Vue.filter('tooltipDuplicate', function (value) {
  if (!value) return 'Duplicate'
  value = value.toString()
  return `Duplicate ${value}`
})

Vue.filter('tooltipEdit', function (value) {
  if (!value) return 'Edit'
  value = value.toString()
  return `Edit ${value}`
})
