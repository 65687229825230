import Layout from './Layout'

export default [
  {
    // Blank layout
    path: '/modules',
    component: Layout,
    children: [
      {
        path: '',
        name: 'modules',
        component: () => import('@/pages/programs/Modules'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':programId/edit',
        name: 'edit-module',
        component: () => import('@/pages/programs/EditProgram'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
    ],
  },
  {
    // Blank layout
    path: '/user-programs',
    component: Layout,
    children: [
      {
        path: '',
        name: 'user-programs',
        component: () => import('@/pages/programs/UserPrograms'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':programId/edit',
        name: 'edit-user-program',
        component: () => import('@/pages/programs/EditProgram'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
    ],
  },
  {
    // Blank layout
    path: '/hit-programs',
    component: Layout,
    children: [
      {
        path: '',
        name: 'hit-programs',
        component: () => import('@/pages/programs/HitPrograms'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':programId/edit',
        name: 'edit-prodhituct-program',
        component: () => import('@/pages/programs/EditProgram'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
    ],
  },
]
