import Layout from './Layout'

export default [{
  // Blank layout
  path: '/exercises',
  component: Layout,
  children: [
    {
      path: '',
      name: 'exercises',
      component: () => import('@/pages/exercises/Exercises'),
      meta: {
        requiresAuth: true
      }
    }, {
      path: ':exerciseId/edit',
      name: 'editExercise',
      component: () => import('@/pages/exercises/EditExercise'),
      meta: {
        requiresAuth: true
      },
      props: true
    }
  ]
}]
