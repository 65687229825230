import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

export default [{
  // Blank layout
  path: '/users',
  component: LayoutHorizontalSidenav,
  children: [
    {
      path: '',
      name: 'users',
      component: () => import('@/pages/users/Users'),
      meta: {
        requiresAuth: true
      }
    }, {
      path: 'new',
      name: 'newUser',
      component: () => import('@/pages/users/EditUser'),
      meta: {
        requiresAuth: true
      }
    }, {
      path: ':id/edit',
      name: 'editUser',
      component: () => import('@/pages/users/EditUser'),
      meta: {
        requiresAuth: true
      },
      props: true
    }
  ]
}]
