<template>
  <validation-provider tag="div" :rules="rules" :name="name" :vid="vid" v-slot="validationContext" mode="eager">
    <b-input
      :type="type"
      v-model="currentValue"
      :state="validationContext.touched ? validationContext.valid : null"
      :autofocus="autofocus"
      :placeholder="placeholder || name.toLowerCase()"
      :maxLength="maxLength"
      :disabled="disabled"
    />
    <span class="text-danger small">{{ validationContext.errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'validated-text-input',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    maxLength: {
      type: Number,
      default: 2048
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },

      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
